'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { DateValue, now, today, toZoned } from '@internationalized/date'
import { Button, Select, SelectItem, SelectSection } from '@nextui-org/react'
import { I18nProvider } from '@react-aria/i18n'
import { format } from 'date-fns'
import { useLocale, useTranslations } from 'next-intl'
import { union } from 'ramda'
import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useEffectOnce } from 'react-use'
import { z } from 'zod'

import { env } from '@/constants/configs'
import { fetchBranches, fetchCommon } from '@/libs/actions/common.action'
import { generateAvailableDatetime } from '@/libs/date'
import { geoBeanchLocation } from '@/libs/haversine'
import { locales } from '@/libs/intl'

import { DatePicker } from '../ui/datepicker'

const selectClassNames = {
  trigger: 'shadow-none rounded bg-theme-200/20 hover:!bg-theme-200/25',
  label: 'text-theme/90',
  value: 'font-bold',
  popoverContent: 'rounded'
}
const selectItemClassNames = 'rounded data-[selected=true]:!bg-theme-200/60'

const formSchema = z.object({
  cs_location: z.string(),
  cs_appdate: z.string(),
  cs_apptime: z.string(),
  cs_guest: z.string(),
  csrf: z.string().optional()
})

type Form = z.infer<typeof formSchema>

export function BookingFormComponent() {
  // __STATE's
  const formRef = useRef<HTMLFormElement>(null)
  const form = useForm<Partial<Form>>({ resolver: zodResolver(formSchema) })

  const locale = useLocale()
  const t = useTranslations()

  const currentLocale = useMemo(() => locales.find((r) => r.key === locale), [locale])

  const [branches, setBranches] = useState<IBranch[]>([])
  const [currentBranch, setCurrentBranch] = useState<Branch>()
  const [intervals, setIntervals] = useState(() =>
    generateAvailableDatetime(now(env.TZ), '10:00', '22:00')
  )
  const [currentActionUrl, setCurrentActionUrl] = useState(
    `${env.APP_BASE_URL}/${currentLocale?.key}/onlinebooking/`
  )

  // __FUNCTION's
  const onSubmit = () => formRef.current?.submit()
  const current = now(env.TZ)
  const minDate = current.hour < 21 ? current : current.add({ days: 1 })

  const handleBranchChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const branchId = e.target.value
      const branch = branches
        ?.map((r) => r.branches)
        .reduce<Branch[]>(union, [])
        .find((r) => r.id === branchId)

      if (branch) {
        setCurrentBranch(branch)

        const [open, close] = [branch.open_timename, branch.close_timename]
        const intervals = generateAvailableDatetime(now(env.TZ), open, close, env.TZ)
        setIntervals(intervals)

        if (branch?.cross_http_origin) {
          setCurrentActionUrl(`${branch.cross_http_origin}/${currentLocale?.key}/onlinebooking/`)
        } else {
          setCurrentActionUrl(`${env.APP_BASE_URL}/${currentLocale?.key}/onlinebooking/`)
        }

        form.resetField('cs_appdate')
        form.resetField('cs_apptime')
      }

      form.setValue('cs_location', branchId)
    },
    [branches, currentLocale]
  )

  const handleDateChange = useCallback(
    (value: DateValue) => {
      if (!currentBranch) return void 0

      const appdate = toZoned(value, env.TZ)
      const [open, close] = [currentBranch.open_timename, currentBranch.close_timename]
      const intervals = generateAvailableDatetime(appdate, open, close, env.TZ)
      setIntervals(intervals)

      form.resetField('cs_apptime')
      form.setValue('cs_appdate', format(appdate.toDate(), 'yyyyMMdd'))
    },
    [currentBranch]
  )

  // __EFFECT's
  useEffectOnce(() => {
    async function fetcher() {
      const [common, branches] = await Promise.all([fetchCommon(locale), fetchBranches(locale)])

      if (common) form.setValue('csrf', common.CSRF_TOKEN)
      if (branches) {
        try {
          const branch = await geoBeanchLocation(
            branches.map((r) => r.branches).reduce<Branch[]>(union, [])
          )
          if (branch) {
            const branchSorted = branches.sort((a) => (a.code === branch.city_code ? -1 : 1))
            setBranches(branchSorted)
          } else {
            setBranches(branches)
          }
        } catch (error) {
          setBranches(branches)
        }
      }
    }

    fetcher()
  })

  // __RENDER
  return (
    <form
      className='flex min-w-72 flex-col flex-wrap gap-8'
      method='post'
      action={currentActionUrl}
      onSubmit={form.handleSubmit(onSubmit)}
      ref={formRef}>
      <input type='hidden' {...form.register('csrf')} />
      <input type='hidden' {...form.register('cs_location')} />
      <input type='hidden' {...form.register('cs_appdate')} />
      <input type='hidden' {...form.register('cs_apptime')} />
      <input type='hidden' {...form.register('cs_guest')} />

      <Select
        classNames={selectClassNames}
        key='PLACEHOLDER_LOCATION'
        color='primary'
        variant='flat'
        size='sm'
        radius='none'
        label={t('Menu.PLACEHOLDER_LOCATION')}
        labelPlacement='inside'
        isRequired
        scrollShadowProps={{ isEnabled: false }}
        errorMessage={form.formState.errors?.cs_location?.message}
        onChange={handleBranchChange}>
        {branches?.map((record) => (
          <SelectSection
            classNames={{ heading: 'text-sm font-bold text-theme' }}
            title={record.name}
            key={record.code}>
            {record.branches.map((branch) => (
              <SelectItem className={selectItemClassNames} value={branch.id} key={branch.id}>
                {branch.name}
              </SelectItem>
            ))}
          </SelectSection>
        ))}
      </Select>

      <I18nProvider locale={currentLocale?.locale}>
        <DatePicker
          key='PLACEHOLDER_DATE'
          label={t('Menu.PLACEHOLDER_DATE')}
          value={form.watch('cs_appdate')}
          minDate={minDate}
          maxDate={today(env.TZ).add({ years: 1 })}
          errorMessage={form.formState.errors?.cs_appdate?.message}
          onChange={handleDateChange}
        />
      </I18nProvider>

      <Select
        classNames={selectClassNames}
        key='PLACEHOLDER_TIME'
        color='primary'
        variant='flat'
        size='sm'
        radius='none'
        label={t('Menu.PLACEHOLDER_TIME')}
        labelPlacement='inside'
        // value={form.watch('cs_apptime')}
        isRequired
        scrollShadowProps={{ isEnabled: false }}
        errorMessage={form.formState.errors?.cs_apptime?.message}
        onChange={({ target }) => form.setValue('cs_apptime', target.value)}>
        {intervals.map((record, index) => {
          const hours = String(record.hour).padStart(2, '0')
          const minutes = String(record.minute).padStart(2, '0')
          const value = `${hours}:${minutes}`

          return (
            <SelectItem className={selectItemClassNames} value={value} key={value}>
              {value}
            </SelectItem>
          )
        })}
      </Select>

      <Select
        classNames={selectClassNames}
        key='PLACEHOLDER_GUEST_BG'
        color='primary'
        variant='flat'
        size='sm'
        radius='none'
        label={t('Menu.PLACEHOLDER_GUEST_BG')}
        labelPlacement='inside'
        isRequired
        scrollShadowProps={{ isEnabled: false }}
        errorMessage={form.formState.errors?.cs_guest?.message}
        onChange={({ target }) => form.setValue('cs_guest', target.value)}>
        {Array.from({ length: 10 }).map((_, index) => (
          <SelectItem
            className={selectItemClassNames}
            value={Number(index) + 1}
            key={Number(index) + 1}>
            {String(index + 1)}
          </SelectItem>
        ))}
      </Select>

      <Button
        className='rounded'
        type='submit'
        color='primary'
        size='lg'
        radius='none'
        aria-label='Button submit booking'>
        {t('Menu.BOOKNOW_BUTTON')}
      </Button>
    </form>
  )
}
