import { DateValue, now, toZoned, ZonedDateTime } from '@internationalized/date'

import { env } from '@/constants/configs'

export function generateAvailableDatetime(
  date: DateValue,
  from: string,
  to: string,
  tz: string = env.TZ
): ZonedDateTime[] {
  const toTime = (str: string) => {
    const [hh, mm] = str.split(':')

    return {
      str,
      hour: +hh,
      minute: +mm
    }
  }

  const [open, close] = [toTime(from), toTime(to)]
  const currentDatetime = toZoned(date, tz)
  let current = now(tz)
  const isToday = currentDatetime.toDate().toDateString() === current.toDate().toDateString()
  if (isToday) {
    current = current.add({ hours: 2 })
  }

  let start = currentDatetime.set({
    hour: open.hour,
    minute: open.minute,
    second: 0,
    millisecond: 0
  })

  let end = currentDatetime.set({
    hour: close.hour === 0 ? 23 : close.hour - 1,
    minute: close.minute,
    second: 0,
    millisecond: 0
  })

  if (start.hour > end.hour) {
    end = end.add({ days: 1 })
  }

  const intervals = generateTimeIntervals(start, end, 30)

  return intervals.filter((r) => r.compare(current) > 0)
}

export function generateTimeIntervals(start: ZonedDateTime, end: ZonedDateTime, step: number = 30) {
  const intervals: ZonedDateTime[] = []
  let current = start

  while (current.compare(end) <= 0) {
    intervals.push(current)
    current = current.add({ minutes: step })
  }

  return intervals
}

export function groupByDate(intervals: ZonedDateTime[]): Record<string, ZonedDateTime[]> {
  return intervals.reduce<Record<string, ZonedDateTime[]>>((acc, interval) => {
    const dateKey = interval.toString().slice(0, 10)

    if (!acc[dateKey]) {
      acc[dateKey] = []
    }

    acc[dateKey].push(interval)

    return acc
  }, {})
}
