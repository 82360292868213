export function haversineDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
  const toRad = (value: number) => (value * Math.PI) / 180

  const dLat = toRad(lat2 - lat1)
  const dLon = toRad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  // Radius of the Earth (unit: kilometers)
  return 6371 * c
}

export function findClosestLocation(branches: Branch[], reference: GeolocationCoordinates) {
  let currentBranch = null
  let minDistance = Infinity

  for (const branch of branches) {
    const distance = haversineDistance(
      reference.latitude,
      reference.longitude,
      parseFloat(branch.latitude),
      parseFloat(branch.longtitude)
    )

    if (distance < minDistance) {
      minDistance = distance
      currentBranch = branch
    }
  }

  // Distance (km)
  return { branch: currentBranch, distance: minDistance }
}

export async function geoBeanchLocation(branches: Branch[]): Promise<Branch | null> {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          const result = findClosestLocation(branches, coords)

          if (result.branch && result.distance < 25) {
            resolve(result.branch)
          }
        },
        (error) => {
          reject(null)
        }
      )
    } else {
      reject(null)
    }
  })
}
