'use client'

import { getLocalTimeZone, type DateValue } from '@internationalized/date'
import { Calendar, Input, Popover, PopoverContent, PopoverTrigger } from '@nextui-org/react'
import { useDateFormatter } from '@react-aria/i18n'
import { CalendarDays } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'

type Props = {
  label: string
  value?: string
  minDate?: DateValue
  maxDate?: DateValue
  errorMessage?: any
  onChange?: (value: DateValue) => void
}

export function DatePicker({ label, value, ...rest }: Props) {
  // __STATE's
  const formatter = useDateFormatter({ dateStyle: 'medium' })

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [currentValue, setCurrentValue] = useState<DateValue | null>()
  const displayValue = useMemo(() => {
    if (currentValue) {
      const date = currentValue.toDate(getLocalTimeZone())
      return formatter.format(date)
    }

    return ''
  }, [currentValue])

  // __EFFECT's
  useEffect(() => {
    if (!value) {
      setCurrentValue(null)
    }
  }, [value])

  // __RENDER
  return (
    <Popover isOpen={isOpen} offset={10} placement='bottom' onOpenChange={setIsOpen}>
      <PopoverTrigger>
        <Input
          classNames={{
            label: 'text-theme/90',
            input: 'text-left text-sm font-bold !text-theme',
            inputWrapper: 'rounded !bg-theme-200/20 shadow-none hover:!bg-theme-200/25'
          }}
          size='sm'
          radius='none'
          labelPlacement='inside'
          label={label}
          value={displayValue}
          isRequired
          isReadOnly
          errorMessage={rest?.errorMessage}
          endContent={<CalendarDays className='pointer-events-none size-5 text-theme' />}
        />
      </PopoverTrigger>

      <PopoverContent className='rounded-none bg-transparent p-0 shadow-none'>
        {() => (
          <Calendar
            showMonthAndYearPickers
            value={currentValue as any}
            minValue={rest?.minDate}
            maxValue={rest?.maxDate}
            onChange={(value) => {
              setCurrentValue(value)
              setIsOpen(false)
              if (rest?.onChange) rest.onChange(value)
            }}
          />
        )}
      </PopoverContent>
    </Popover>
  )
}
