import { env } from '@/constants/configs'

export async function fetchCommon(locale: string = 'en') {
  const response = await fetch(`${env.APP_BASE_URL}/api-v2/common/?locale=${locale}`)
  const data = await response.json()

  return data
}

export async function fetchBranches(locale: string = 'en'): Promise<IBranch[]> {
  const response = await fetch(`${env.APP_BASE_URL}/api-v2/branches/?locale=${locale}`)
  const data = await response.json()

  return data
}
